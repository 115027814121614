<template>
  <div class="pb-2 px-5 text-center">
    <img :src="computedImg">
    <h4 class="mt-2">{{ computedTitle }}</h4>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'NoResults',
  props: {
    img: { type: String, required: false, default: '' },
    title: { type: String, required: false, default: '' },
    msg: { type: String, required: false, default: '' }
  },
  computed: {
    computedImg () { return this.img !== '' ? this.img : '/media/img/no_results.png' },
    computedTitle () { return this.title !== '' ? this.title : this.$t('GENERAL.no_results') },
    text () { return this.msg !== '' ? this.msg : this.$t('GENERAL.no_results_sub') }
  }
}
</script>

<style lang="scss" scoped>
  img {
    width: 30%;
    max-width: 300px;
  }

  @media (max-width: $mobile-breakpoint) {
    img {
      width: 80%;
    }
  }
</style>
