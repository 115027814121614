<template>
  <b-card no-body img-top>
    <b-skeleton-img card-img="top" width="100%"></b-skeleton-img>
    <b-card-body>
      <div>
        <b-skeleton width="100%" height="24px"></b-skeleton>
        <b-skeleton width="70%" height="24px" class="mt-2"></b-skeleton>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'CategoryCardSkeleton'
}
</script>
