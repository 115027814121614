<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="$t('TERMS.labs')"
    :description="$t('LABS.section_description')"
    :loading="isLoading"
    />

    <!-- SKELETONS LOADING -->
    <b-row class="main-container py-5 px-md-5" v-if="isLoading">
      <b-col class="mb-3" sm="12" md="6" lg="4" xl="3" v-for="n in Array(12)" v-bind:key="n">
        <CategoryCardSkeleton />
      </b-col>
    </b-row>
    <!-- END SKELETONS LOADING -->
    <div class="main-container py-5">
      <b-row
        class="px-md-5 no-lateral-margin"
      >
        <b-col
        sm="12" md="6" lg="4" xl="3"
        v-for="lab in translatedLabs"
        v-bind:key="lab.id"
        class="mb-3"
        >
          <CategoryCard
            :img="getUrlImg(lab.image)"
            :title="lab.name"
            :text="lab.description"
            :progress="calculateProgress(lab.completed_machines, lab.vms.length)"
            :to="{ name: 'labDetail', params: { id: lab.id, name: lab.name }}"
          />
        </b-col>
      </b-row>

      <NoResults v-if="!isLoading && labs.length === 0" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryCard from '@/components/utils/cards/CategoryCard'
import CategoryCardSkeleton from '@/components/skeletons/CategoryCardSkeleton'
import SectionHeader from '@/components/SectionHeader'
import NoResults from '@/components/utils/NoResults'

import mapper from '@/services/mixins/mapper.mixins'
import progress from '@/services/mixins/progress.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'Labs',
  mixins: [mapper, progress, i18nmixin],
  components: { CategoryCard, SectionHeader, NoResults, CategoryCardSkeleton },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions({
      getLabs: 'labs/getLabs'
    })
  },
  computed: {
    ...mapGetters({
      labs: 'labs/getLabs',
      isLoading: 'labs/isLoading'
    }),
    translatedLabs () { return this.translateObjectArray(this.labs) }
  },
  mounted () {
    this.getLabs()
  }
}
</script>
